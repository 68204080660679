import { Outlet, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const PrivateRoute = ({ adminRoute = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('loggedIn') === 'true';
    const currentRole = sessionStorage.getItem('userRole');
    setIsAuthenticated(loggedIn);

    if (loggedIn && adminRoute && currentRole !== 'admin') {
      setIsAuthenticated(false);
    }

    setLoading(false);
  }, [adminRoute]);

  if (loading) {
    return <div>Loading...</div>; // Ou algum indicador de carregamento
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;